<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new warehouse category</div>
      <div v-else class="p-dialog-title">Warehouse category editing</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <InputText id="warehouseCategory_name" :class="{'p-invalid' : submitted && !itemData.name}" v-model.trim="itemData.name" autocomplete="off"/>
          <label for="warehouseCategory_name">{{ $t('Name') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.name">{{ $t('Required field') }}</small>
      </div>
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <div class="p-float-label">-->
<!--          <InputNumber id="warehouseCategory_sorting" v-model="itemData.sorting" :min="0" :max="250" showButtons autocomplete="off"/>-->
<!--          <label for="warehouseCategory_sorting">Sorting</label>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins ],
  emits: ['close', 'update-items'],
  name: 'WarehouseCategoryModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      itemData: {},
      submitted: false,
      settings
    }
  },
  watch: {
    item() {
      this.itemData = { ...this.item }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.itemData.name) {
        return false
      }

      const warehouseCategory = {
        id: this.itemData.id ?? null,
        name: this.itemData.name ?? null,
        sorting: this.itemData.sorting ?? null,
      }

      if (this.itemData.id) {
        // warehouseCategory.id = this.itemData.id
        try {
          const { status } = await httpClient.post(`warehouse/update-category`, warehouseCategory)
          if (status === 200) {
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`warehouse/create-category`, warehouseCategory)
          if (status === 201 && data) {
            this.itemData.id = data
            warehouseCategory.id = data
            // this.$emit('update-items', warehouseCategory, true)
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout) {
    //     return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
    //   } else {
    //     return {position: 'absolute', top: 0, width: '550px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {width: '95%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {width: '420px'}
      } else {
        return {width: '420px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>