<template v-if="item">
  <div v-tooltip.top="Statuses.find(state => state.num === item.status)?.label[$i18n.locale]"
       class="item-status-wrapper"
       @contextmenu="onWarehouseItemStatusClick($event, item)">
    <div class="item-status" :class="Statuses.find(pos => pos.num === item.status)?.color"><span v-if="item.request_state_history?.length">{{ item.request_state_history.length }}</span></div>
    <WarehouseItemContextMenu
        @leaf-click="changeItemStatus($event, item.state, item)"
        ref="warehouseItemsStatusesMenuAccordion"
        :model="Statuses"/>
  </div>
  <!--  <div v-else class="item-status item-status&#45;&#45;big"></div>-->
</template>
<script>

import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";
import constants from '@/constants'
// import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
import Statuses from "@/translations/states/Statuses";
import WarehouseItemContextMenu from "@/pages/warehouse/components/WarehouseItemContextMenu";

export default {
  components: { WarehouseItemContextMenu },
  mixins: [ formatMixins, httpMixins ],
  emits: ['update-items'],
  name: 'WarehouseItemStatusButton',
  props: {
    orderIsArchived: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    orderId: Number,
    item: Object,
    // disabled: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      // itemToUpdate: null,
      Statuses,
      constants
    }
  },
  methods: {
    // onWarehouseItemStatusClick(event, item) {
    onWarehouseItemStatusClick(event) {
      // if (this.isDisabled ||
      //     (item.status === 0 && (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin))
      //     || (this.orderIsArchived && (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin))) {
      //   event.preventDefault()
      //   return false
      // }
      this.$refs.warehouseItemsStatusesMenuAccordion.show(event);
    },
    async changeItemStatus(newStatus, oldStatus, item) {
      item.status = newStatus
      const obj = { itemId: item.id, status: newStatus}
      try {
        const { status } = await httpClient.post('warehouse/change-item-state', obj)
        if (status === 200) {
          this.updatedItem = null
          this.$emit('update-items', item.id, false) //false = dont' get warehouse items
        }
      } catch(err) {
        item.status = oldStatus
        this.showError(err)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.item-status-wrapper {
  display: block;
  padding: 6px;
  cursor: pointer;
  border-radius: 2px;
  //min-width: 23px;
  &:hover {
    opacity: 0.7;
  }
}

.item-status {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2px;
  height: 14px;
  width: 14px;
  border-radius: 50px;
  font-size: 0.9em;
}
.red {
  background: #ff6464;
}
.green {
  background: #60c666;
}
//.item-status--big {
//  height: 32px;
//  width: 32px;
//}

</style>